/*******
* Breakpoints
*******/

$screen-xs: 480px;
$screen-sm: 640px;
$screen-md: 768px;
$screen-lg: 1024px;
$screen-xl: 1280px;
$screen-2xl: 1536px;
