@import 'variables/_variables.scss';

/* BASE */
@import 'tailwindcss/base';
@import 'base/_base.scss';
@import 'base/_fonts.scss';
@import 'base/_reset.scss';
@import 'base/_typo.scss';
@import 'base/_link.scss';
@import 'base/_form.scss';
@import 'base/_button.scss';

/* COMPONENTS */
@import 'tailwindcss/components';

/* UTILITIES */
@import 'tailwindcss/utilities';

@import 'components/_container.scss';
