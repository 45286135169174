@layer components {
  %link {
    @apply cursor-pointer decoration-1 underline-offset-2 transition-all duration-200 hover:underline;
  }

  .link-white {
    @extend %link;
    @apply text-white;
  }

  .link-primary {
    @extend %link;
    @apply text-primary-500 hover:text-primary-700;
  }

  .link {
    @extend %link;
  }
}
