.typo-h1 {
  @apply text-3xl font-thin md:text-4xl;
}

.typo-h3 {
  @apply text-xl font-thin;
}

.typo-base {
  @apply font-base text-base;
}

.typo-lg {
  @apply text-lg font-thin;
}
