@font-face {
  font-family: 'HelveticaNeue';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('../../fonts/new light special.woff') format('woff');
}

@font-face {
  font-family: 'HelveticaNeue';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../../fonts/new roman.woff') format('woff');
}

@font-face {
  font-family: 'HelveticaNeue';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('../../fonts/new bold.woff') format('woff');
}
