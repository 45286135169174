.form-input,
.form-select,
.form-textarea {
  @apply block w-full appearance-none rounded-none border px-2 py-2.5 text-left text-inherit transition-colors duration-200 focus:shadow-none focus:ring-0;

  @apply border-gray-200 bg-white text-black placeholder-gray-500 focus:border-primary-500;
}

.form-select {
  @apply h-[2.625rem];
}

.form-textarea {
  @apply leading-normal;
}

.form-checkbox,
.form-radio {
  @apply h-6 w-6 cursor-pointer border-gray-200 bg-white leading-none text-primary-500 transition-colors duration-200 focus:border-primary-500 focus:ring focus:ring-primary-500 focus:ring-opacity-50;

  + label {
    @apply cursor-pointer pl-2;

    a {
      @apply border-b border-transparent text-primary-500 transition duration-150 ease-in-out;

      &:hover {
        @apply border-current;
      }
    }
  }

  &:disabled {
    @apply opacity-50;
  }
}

.form-checkbox {
  @apply rounded-sm;
}

.form-radio {
  @apply relative rounded-full;

  + label {
    @apply cursor-pointer pl-0;
  }

  &:checked {
    background-image: none;
    @apply border-primary-500 bg-white ring-offset-0 hover:border-primary-500 hover:bg-white hover:ring hover:ring-primary-500 hover:ring-opacity-50 focus:border-primary-500 focus:bg-white focus:ring focus:ring-primary-500 focus:ring-opacity-50;

    &:before {
      content: '';
      @apply absolute left-1/2 top-1/2 h-3.5 w-3.5 -translate-x-1/2 -translate-y-1/2 transform rounded-full bg-primary-500;
    }
  }
}

.form-type-tabs {
  @apply inline-flex divide-x divide-gray-200 border border-gray-200;
}

.form-input-error,
.form-textarea-error,
.form-checkbox-error,
.form-radio-error,
.form-select-error {
  @apply border-error-500 focus:border-error-500;
}

.form-type-tabs-error {
  @apply divide-error-500 border-error-500 focus:border-error-500;
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  @apply border-black;
  -webkit-text-fill-color: theme('colors.black');
}

.form-input-icon {
  @apply absolute inset-y-0 right-0 my-auto mr-3 h-4 w-4;
}
